import {
  type ClientLoaderFunctionArgs,
  useLoaderData,
  useNavigate,
} from '@remix-run/react';
import { useCallback } from 'react';
import { Provider as ReduxProvider } from 'react-redux';

import { useUserAnalytics } from '../analytics/user';
import { UserAccess } from '../components/Access/UserAccess';
import { ConfirmCancelModalProvider } from '../components/ConfirmCancelModalContext';
import { ProvidersList } from '../components/ProvidersList';
import { PublicHomeLayout } from '../components/PublicHome/Layout';
import { Settings } from '../components/Settings/Settings';
import { MenuKey } from '../components/Settings/types';
import { settingsPath } from '../components/Settings/utils';
import { UserContextProvider, useUser } from '../components/UserContext';
import { useTitle } from '../hooks/useTitle';
import { AdminView } from '../pages/Admin/AdminView';
import { store } from '../store/configureStore';
import { RoleUtils } from '../types';
import { makeTitle } from '../utils/common';
import { setAPIServiceClientSecureToken } from '../utils/setAPIClientToken';

setAPIServiceClientSecureToken();

function SettingsPage() {
  const { selectedMenuKey } = useLoaderData<typeof clientLoader>();
  const navigate = useNavigate();

  const handleMenuSelected = useCallback(
    (menuKey: MenuKey) => {
      navigate(settingsPath(menuKey));
    },
    [navigate]
  );

  return (
    <Settings
      selectedMenuKey={selectedMenuKey}
      onMenuSelected={handleMenuSelected}
    />
  );
}

export async function clientLoader(action: ClientLoaderFunctionArgs) {
  const selectedMenuKey = action.params.tab
    ? Object.values(MenuKey).find(
        (enumValue) => enumValue === action.params.tab
      )
    : MenuKey.Account;

  return { selectedMenuKey };
}

function ComponentInternal() {
  const user = useUser();
  const isAdmin = RoleUtils.isAdmin(user);

  if (isAdmin) {
    return (
      <AdminView>
        <SettingsPage />
      </AdminView>
    );
  } else {
    return (
      <PublicHomeLayout>
        <div className='relative flex-1 min-h-0'>
          <SettingsPage />
        </div>
      </PublicHomeLayout>
    );
  }
}

export function Component() {
  useTitle(makeTitle('Settings'));

  const providers = [
    <ReduxProvider store={store} children={[]} />,
    <UserContextProvider useUserAnalytics={useUserAnalytics} />,
    <ConfirmCancelModalProvider />,
    <UserAccess />,
  ];

  return (
    <ProvidersList providers={providers}>
      <ComponentInternal />
    </ProvidersList>
  );
}
